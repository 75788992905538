import React from "react";

import { Box, Container, Typography } from "@mui/material";

import Navbar from "../navbar";
import bgImage from "../../assets/main/[BG]-LANDING-PAGE.jpg";
import SearchNav from "../search-nav/SearchNav";

const Hero = ({ id }) => {
  return <Container
    id={id}
    maxWidth="false"
    disablegutters = "true"
    sx={{
      background: `url(${bgImage}) center center/cover`,
      minHeight: "800px",
      borderRadius: {
        xs: "0px 0px 27px 27px",
        md: "0px 0px 54px 54px"
      }
    }}
  >
    <Box sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    }}>
      <Navbar />
      <Typography
        sx={{
          color: "#fff",
          marginTop: "250.5px",
          textAlign: "center",
          fontWeight: "450",
          fontSize : {
            xs: "150px",
            md: "280px"
          },
          fontFamily: 'Palace Script MT Regular',
          lineHeight: "62px",
          // mb: 4
        }}
      >
        Jesus
        <Typography sx={{
          color: "#fff",
          marginTop: "75px",
          textAlign: "center",
          fontWeight: "600",
          letterSpacing: '8px',
          fontSize : {
            xs: "25px",
            md: "48px"
          },
          fontFamily: 'Perpetua Titling MT Bold'
          // lineHeight: "62px",
          // mb: 4
        }}>
          MIRACLE CRUSADE
        </Typography>
        <Typography sx={{
          color: "#fff",
          // marginTop: "196.5px",
          textAlign: "center",
          fontWeight: "600",
          letterSpacing: '8px',
          fontSize : {
            xs: "25px",
            md: "48px"
          },
          fontFamily: 'Perpetua Titling MT Bold'
          // lineHeight: "62px",
          // mb: 4
        }}>
          INTERNATIONAL MINISTRY
        </Typography>
        <Typography sx={{
          color: "#fff",
          // marginTop: "196.5px",
          textAlign: "center",
          // fontWeight: "600",
          letterSpacing: '7px',
          fontSize : {
            xs: "38px",
            md: "48px"
          },
          fontFamily: 'Perpetua Titling MT'
          // lineHeight: "62px",
          // mb: 4
        }}>
          CALGARY OUTREACH
        </Typography>
      </Typography>
      <SearchNav />
    </Box>
  </Container>;
};

export default Hero;
