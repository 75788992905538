import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Container, Typography, Grid } from "@mui/material";

import carouselImage1 from "../../assets/main/carousel-images/carousel-image-1.jpg";
import carouselImage2 from "../../assets/main/carousel-images/carousel-image-2.jpg";
import carouselImage3 from "../../assets/main/carousel-images/carousel-image-3.jpg";
import carouselImage4 from "../../assets/main/carousel-images/carousel-image-4.jpg";
import carouselImage5 from "../../assets/main/carousel-images/carousel-image-5.jpg";
import carouselImage6 from "../../assets/main/carousel-images/carousel-image-6.jpg";
import carouselImage7 from "../../assets/main/carousel-images/carousel-image-7.jpg";
import carouselImage8 from "../../assets/main/carousel-images/carousel-image-8.jpg";

const items = [
  { id: 1, src: carouselImage1, title: 'Item 1', content: 'Content for Item 1' },
  { id: 2, src: carouselImage2, title: 'Item 2', content: 'Content for Item 2' },
  { id: 3, src: carouselImage3, title: 'Item 3', content: 'Content for Item 3' },
  { id: 4, src: carouselImage4, title: 'Item 4', content: 'Content for Item 4' },
  { id: 5, src: carouselImage5, title: 'Item 5', content: 'Content for Item 5' },
  { id: 6, src: carouselImage6, title: 'Item 6', content: 'Content for Item 6' },
  { id: 7, src: carouselImage7, title: 'Item 7', content: 'Content for Item 7' },
  { id: 8, src: carouselImage8, title: 'Item 8', content: 'Content for Item 8' },
];

const Carousel = ({ id }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <Container
    disablegutters = "true"
      id={id}
      maxWidth="xl"
      style={{
        marginTop: '50px'
      }}
    >
      <div style={{ backgroundColor: '#162485', padding: '50px', width: '100%' }}>
        <div style={{ backgroundColor: 'white', padding: '10px', width: '100%' }}>
          <Typography
            sx={{
              letterSpacing: '7px',
              fontFamily: 'Perpetua Titling MT Bold',
              fontWeight: "600",
              fontSize : {
                xs: "20px",
                md: "48px"
              },
              color: "#161414",
              mb: 5,
              textAlign: {
                xs : "center",
                md: "center"
              }
            }}
          >
          RECENT EVENTS
          </Typography>
          <Slider {...settings}>
            {items.map((item) => (
              <div key={item.id}>
                <img 
                src={item.src} 
                alt={item.title} 
                style={{ 
                  maxWidth: '100%', 
                  height: '300px', 
                  margin: '0 auto',
                  objectFit: 'cover',
                  }} 
                />
              </div>
            ))}
          </Slider>
          <Typography sx={{
          marginTop: "15px",
          textAlign: "center",
          letterSpacing: '7px',
          fontSize : {
            xs: "20px",
            md: "48px"
          },
          fontFamily: 'Perpetua Titling MT'
        }}>
          JMCIM CALGARY
            <Typography sx={{
            textAlign: "center",
            letterSpacing: '7px',
            fontSize : {
              xs: "20px",
              md: "48px"
            },
            fontFamily: 'Perpetua Titling'
          }}>
            Covid - 19 Relief Response
            </Typography>
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default Carousel;
