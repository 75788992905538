import React from 'react';
import { Grid, Typography, Container, Box } from "@mui/material";

import mission from "../../assets/main/MISSION.jpg";
import vision from "../../assets/main/VISION.jpg"
import bgForTheChurch from "../../assets/main/[BGIMAGE]-THE-CHURCH.png"


const MissionAndVision = () => {
  return (
    <Container
      maxWidth="xxl"
      style={{
        background: `url(${bgForTheChurch}) center center/cover`,
        paddingTop: "45px"
      }}
    >
      <Typography sx={{
        textAlign: "center",
        letterSpacing: '7px',
        fontSize : {
          xs: "38px",
          md: "48px"
        },
        fontFamily: 'Perpetua Titling MT',
        paddingBottom: '10px'
      }}>
        MISSION AND VISION
      </Typography>
      <Container maxWidth="lg">
        {/* First Grid */}
        <Grid container spacing={3} alignItems="center" style={{
          marginBottom: '50px'
        }}>
          {/* Left Column (Picture) */}
          <Grid item xs={12} md={6}>
            {/* Replace 'imageUrl' with the actual URL of your image */}
            <img src={mission} alt="Your Image" style={{ width: '100%', height: 'auto' }} />
          </Grid>

          {/* Right Column (Title and Paragraph) */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
              THE MISSION
            </Typography>
            <Typography variant="body1">
              <Box sx={{
                  fontWeight: 'bold'
                }}>
                  JESUS
              </Box>
              <Box sx={{
                marginBottom: '20px'
              }}>
                To magnify Our Dearest Jesus Christ as the One Only True God, Lord, and Savior of all mankind.
              </Box>
              <Box sx={{
                fontWeight: 'bold'
              }}>
                MIRACLE
              </Box>
              <Box sx={{
                marginBottom: '20px'
              }}>
                To release the power of healing / miracles by prayer and fasting, through our Lord and Savior Jesus Christ, to those who are sick and have diseases.
              </Box>
              <Box sx={{
                fontWeight: 'bold'
              }}>
                CRUSADE
              </Box>
              <Box sx={{
                marginBottom: '20px'
              }}>
                To conduct crusades that are open to everybody to introduce to the lost our Lord and Savior Jesus Christ.
              </Box>
              <Box sx={{
                fontWeight: 'bold'
              }}>
                MINISTRY
              </Box>
              <Box sx={{
                marginBottom: '20px'
              }}>
                To nurture, train, and empower the brethren for the work of the Ministry.
              </Box>
            </Typography>
          </Grid>
        </Grid>

        {/* Second Grid */}
        <Grid container spacing={3} alignItems="center" style={{
          paddingBottom: '20px'
        }}>
          {/* Left Column (Picture) */}
          <Grid item xs={12} md={6}>
            {/* Replace 'imageUrl' with the actual URL of your image */}
            <img src={vision} alt="Your Image" style={{ width: '100%', height: 'auto' }} />
          </Grid>

          {/* Right Column (Title and Paragraph) */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
              THE VISION
            </Typography>
            <Typography variant="body1">
              <Box>
                To bring this life-changing gospel to every corner and every city of the world and win more souls for Christ.
              </Box>
              <Box>
              "And He said unto them, Go ye into all the world, and preach the gospel to every creature, he that believeth and is baptized shall be saved; but he that believeth not shall be damned"
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default MissionAndVision;
