import React from "react";
import { Box, Container, Typography } from "@mui/material";
import bgForJesusHeals from '../../assets/main/[BANNER]-JESUS-HEALS.jpg';
import { useTheme } from '@mui/material/styles';

const LightBlueJmcimChurch = () => {

  const theme = useTheme();

  return (
    <>
      {/* Your responsive grid container */}
      <Box
      component="div"
      sx={{
        backgroundImage: `url(${bgForJesusHeals})`,
        backgroundSize: 'contain, cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '50vh', // Adjust as needed
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#ffffff', // Set text color based on your image
        [theme.breakpoints.up('md')]: {
          // Styles for screens md (medium) and larger
          backgroundSize: 'contain, cover',
        },
      }}
    >
      {/* Content */}
    </Box>
    </>
  );
};

export default LightBlueJmcimChurch;
