import { useState, useEffect } from 'react';
import TheJMCIMChurch from "./components/the-jmcim-church/TheJMCIMChurch";
import LightBlueJmcimChurch from "./components/light-blue-jmcim-church/LightBlueJmcimChurch";
import Hero from "./components/hero";
import Carousel from "./components/carousel/Carousel";
import Ministries from "./components/ministries/Ministries";
import LocationAndInfo from "./components/location-and-info/locationAndInfo";
import Footer from "./components/footer/Footer";

import MobileMessage from './components/MobileMessage';
import MissionAndVision from "./components/mission-and-vision/MissionAndVision";

function App() {

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Check the screen width and update the state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 374);
    };

    // Initial check
    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {/* {!isMobile && ( */}
      <div>
        <Hero id="hero" />
        <TheJMCIMChurch id="thechurch" />
        <LightBlueJmcimChurch />
        <MissionAndVision />
        <Carousel id="events" />
        <Ministries id="ministries" />
        <LocationAndInfo id="contactus" />
        <Footer />
      </div>
      {/* )} */}

      {/* {isMobile && (
        <div className="mobile-message">
          Smaller screen sizes may distort or destroy the view as of Jan. 2024 version, please contact Social Media team for further details on this. Thank you so much and God bless you!
        </div>
      )} */}
    </>
  );
}

export default App;
