import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import Navbar from "../navbar";
import SearchNav from "../search-nav/SearchNav";
import almedaFamily from '../../assets/main/almeda-family.jpg'
import preachingMinistry from '../../assets/main/preaching-ministry.jpg'
import youthChoir from '../../assets/main/youth-choir.jpg'
import jfgc from '../../assets/main/jesus-finest-generation-choir.jpg'
import childrensChoir from '../../assets/main/childrens-choir.jpg'
import adultsChoir from '../../assets/main/adults-choir.jpg'

const Ministries = ({ id }) => {
  return (
    <>
        <Typography
            id={id}
            sx={{
                letterSpacing: '7px',
                fontFamily: 'Perpetua Titling MT Bold',
                fontWeight: "600",
                fontSize : {
                    xs: "38px",
                    md: "48px"
                },
                color: "#161414",
                mt: 5,
                mb: 0,
                textAlign: {
                    xs : "center",
                    md: "center"
                }
            }}
            >
            MINISTRIES
        </Typography>
        <Typography sx={{
            letterSpacing: '6px',
            fontFamily: 'Perpetua Titling',
            fontWeight: "300",
            mt: 5,
            fontSize : {
                xs: "38px",
                md: "48px"
            },
            color: "#161414",
            textAlign: {
                xs : "center",
                md: "center"
            }
            }}>
            The Almeda Children
        </Typography>
        <Container
        maxWidth="false"
        disablegutters = "true"
        sx={{
            p: {
            xs: 2,
            sm: 5,
            md: 2,
            },
            background: `url(${almedaFamily}) center center/cover`,
            borderRadius: {
            xs: "0px 0px 27px 27px",
            md: "0px 0px 54px 54px",
            },
            "@media (max-width: 375px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "230px !important",
            width: '100%',
            display: 'center',
            margin: '0 auto'
            },
            // Media query for small screens (e.g., mobile)
            "@media (max-width: 600px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "270px",
            },
            // Media query for medium screens (e.g., tablet)
            "@media (min-width: 601px) and (max-width: 960px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "370px",
            },
            // Media query for medium screens (e.g., laptop)
            "@media (min-width: 961px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "610px",
            },
        }}
        >
        </Container>
        <Typography sx={{
            letterSpacing: '6px',
            fontFamily: 'Perpetua Titling',
            fontWeight: "300",
            mb: 5,
            fontSize : {
                xs: "38px",
                md: "48px"
            },
            color: "#161414",
            textAlign: {
                xs : "center",
                md: "center"
            }
            }}>
            Preaching Ministry
        </Typography>
        <Container maxWidth="lg"
        disablegutters = "true"
        sx={{
            p: {
            xs: 2,
            sm: 5,
            md: 2,
            },
            background: `url(${preachingMinistry}) center center/cover`,
            borderRadius: {
            xs: "0px 0px 27px 27px",
            md: "0px 0px 54px 54px",
            },
            "@media (max-width: 375px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "260px !important",
            width: '100%',
            display: 'center',
            margin: '0 auto'
            },
            // Media query for small screens (e.g., mobile)
            "@media (max-width: 600px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "295px",
            },
            // Media query for medium screens (e.g., tablet)
            "@media (min-width: 601px) and (max-width: 960px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "535px",
            },
            // Media query for medium screens (e.g., laptop)
            "@media (min-width: 961px)": {
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain, cover",
            display: 'center',
            minHeight: "400px",
            },
        }}>
        </Container>
        <Container maxWidth="lg"
        disablegutters = "true"
        >
          <Grid container spacing={10} sx={{
                mb: 5,
                mt: 1.5
            }}>
            {/* Right Grid */}
            <Grid item xs={12} sm={12} md={6}>
            <Typography sx={{
                letterSpacing: '6px',
                fontFamily: 'Perpetua Titling',
                fontWeight: "300",
                mt: 0,
                mb: 0,
                fontSize : {
                    xs: "38px",
                    md: "48px"
                },
                color: "#161414",
                textAlign: {
                    xs : "center",
                    md: "center"
                }
                }}>
                JMCIM JFGC Choir
            </Typography>
              <Box>
                <img
                  src={jfgc} // Replace with the actual URL of your image
                  alt="Sample"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
            {/* Right Grid */}
            <Grid item xs={12} sm={12} md={6}>
            <Typography sx={{
                letterSpacing: '6px',
                fontFamily: 'Perpetua Titling',
                fontWeight: "300",
                mt: 0,
                mb: 0,
                fontSize : {
                    xs: "38px",
                    md: "48px"
                },
                color: "#161414",
                textAlign: {
                    xs : "center",
                    md: "center"
                }
                }}>
                JMCIM Adult's Choir
            </Typography>
              <Box>
                <img
                  src={adultsChoir} // Replace with the actual URL of your image
                  alt="Sample"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg"
        disablegutters = "true"
        >
          <Grid container spacing={10} sx={{
                mb: 5,
                mt: 1.5
            }}>
            {/* Right Grid */}
            <Grid item xs={12} sm={12} md={6}>
            <Typography sx={{
                letterSpacing: '6px',
                fontFamily: 'Perpetua Titling',
                fontWeight: "300",
                mt: 0,
                mb: 0,
                fontSize : {
                    xs: "38px",
                    md: "48px"
                },
                color: "#161414",
                textAlign: {
                    xs : "center",
                    md: "center"
                }
                }}>
                JMCIM Youth Choir
            </Typography>
              <Box>
                <img
                  src={youthChoir} // Replace with the actual URL of your image
                  alt="Sample"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
            {/* Right Grid */}
            <Grid item xs={12} sm={12} md={6}>
            <Typography sx={{
                letterSpacing: '6px',
                fontFamily: 'Perpetua Titling',
                fontWeight: "300",
                mt: 0,
                mb: 0,
                fontSize : {
                    xs: "38px",
                    md: "48px"
                },
                color: "#161414",
                textAlign: {
                    xs : "center",
                    md: "center"
                }
                }}>
                Children's Choir
            </Typography>
              <Box>
                <img
                  src={childrensChoir} // Replace with the actual URL of your image
                  alt="Sample"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
    </>
  );
};

export default Ministries;
