import React from "react";

import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { searchNavItems } from "../../data";

import liveStreamIcon from '../../assets/main/[BUTTON]-LIVESTREAM.png'
import youtubeIcon from '../../assets/main/[BUTTON]-YOUTUBE.png'
import offeringsIcon from '../../assets/main/[BUTTON]-OFFERINGS.png'

const SearchNav = () => {
  return (
    <>
      <Grid container       disablegutters = "true"

    maxWidth="xl" sx={{
        marginTop: '40px'
      }}>
        <Grid item xs={12} md={3} sx={{
          margin: '0 auto',
          textAlign: 'center',
        }}>
          <a href="https://www.facebook.com/jmcim.calgary"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{
              border: 'solid 3px white',
              width: '290px',
              height: '68px',
              marginBottom: '10px',
              textDecoration: 'none',
              color: 'white',
              background: `url(${liveStreamIcon}) center center/cover`,
            }}>
            </Button>
          </a>
          <Typography sx={{
            color: 'white',
          }}>
            Watch our Live Stream Worship Service on Sundays at 10 am.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={{
          margin: '0 auto',
          textAlign: 'center',
        }}>
          <a href="https://www.youtube.com/@jmcimcgymedia/streams"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{
              border: 'solid 3px white',
              width: '290px',
              height: '68px',
              marginBottom: '10px',
              textDecoration: 'none',
              color: 'white',
              background: `url(${youtubeIcon}) center center/cover`,
            }}>
            </Button>
          </a>
          <Typography sx={{
            color: 'white',
          }}>
            Watch or Stream our webcast on our Youtube Channel.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={{
          margin: '0 auto',
          textAlign: 'center',
        }}>
          <Button sx={{
            border: 'solid 3px white',
            width: '290px',
            height: '68px',
            marginBottom: '10px',
            textDecoration: 'none',
            color: 'white',
            background: `url(${offeringsIcon}) center center/cover`,
          }}>
          </Button>
          <Typography sx={{
            color: 'white',
          }}>
            Information about tithes and love offerings.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
};

export default SearchNav;
