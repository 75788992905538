import React from "react";

import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { destinations } from "../../data";
import DestinationItem from "../destination-item";

import continuesTeaching from '../../assets/main/CONTINUES-TEACHING.jpg'
import dailyPrayers from '../../assets/main/DAILY-PRAYERS.jpg'
import helpingCommunity from '../../assets/main/HELPING-COMMUNITY.jpg'
import bgForTheChurch from '../../assets/main/[BGIMAGE]-THE-CHURCH.png'


const TheJMCIMChurch = ({ id }) => {
  return <Container
  id={id}
  disablegutters = "true"
  maxWidth="false"
    sx={{
      px: {
        xs: 2,
        sm: 5,
        md: 10
      },
      my: 10,
      marginBottom: '0px',
      background: `url(${bgForTheChurch}) center center/cover`,
    }}
  >
    <Typography
      sx={{
        letterSpacing: '7px',
        fontFamily: 'Perpetua Titling MT Bold',
        fontWeight: "600",
        fontSize : {
          xs: "38px",
          md: "48px"
        },
        color: "#161414",
        mb: 5,
        textAlign: {
          xs : "center",
          md: "center"
        }
      }}
    >
      THE JMCIM CHURCH
    </Typography>
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        gap: {
          xs: 4,
          md: 1
        },
        alignItems: {
          xs: "center",
          md: "flex-start"
        },
        lineHeight: '2.5'
      }}
    >
      In the worst of times when the immorality of man has equaled that of beasts, and spirituality has been reduced to confusion by the craftiness of satan, by inventing many religions; when man's inventions and technological achievements threaten his very own self to extinction, and the universe ushers into self-destruction; when all attempts to stop criminality and war have failed, and when every knowledge of GOD is supposed as a vain escape from reality by the foolest of fools; through it all, the true Church of the Living God has come out triumphant! The LORD GOD has given full authority to His Church to overcome all the power of darkness - hope still shines after all!
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        gap: {
          xs: 4,
          md: 1
        },
        alignItems: {
          xs: "center",
          md: "flex-start"
        },
        lineHeight: '7'
      }}
    >
      Matthew 16:18 "... upon this rock I will build my Church; and the gates of hell shall not prevail against it."
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        gap: {
          xs: 4,
          md: 1
        },
        alignItems: {
          xs: "center",
          md: "flex-start"
        },
        lineHeight: '2.5',
        marginBottom: '110px'
      }}
    >
      It would be utterly impossible to put into writing all the wonderful works the great GOD has wrought His Church, the JESUS Church, through its spiritual arm, the JESUS MIRACLE CRUSADE INTERNATIONAL MINISTRY. Here is just a very small bit of a vast, colorful picture.
    </Box>
    {/* <Grid container spacing={2} justifyContent="center" alignItems="center">
    <Grid xs={{
      display: "flex"
    }}
    md={2}
    >
        <Typography>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque, quibusdam.
        </Typography>
      </Grid>
      <Grid xs={{
        display: "flex"
      }}
      md={2}
      >
        <Typography>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque, quibusdam.
        </Typography>
      </Grid>
      <Grid xs={{
        display: "flex"
      }}
      md={2}
      >
        <Typography>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque, quibusdam.
        </Typography>
      </Grid>
      <Grid xs={{
        display: "flex"
      }}
      md={2}
      >
       <Typography>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque, quibusdam.
       </Typography>
      </Grid>
      <Grid xs={{
        display: "flex"
      }}
      md={2}
      >
        <Typography>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque, quibusdam.
        </Typography>
      </Grid>
    </Grid> */}
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        gap: {
          xs: 4,
          md: 9
        },
        justifyContent: "space-between",
        alignItems: {
          xs: "center",
          md: "flex-start"
        }
      }}
    >
      {/* <Box sx={{
        height: '300px',
        width: '500px',
        background: `url(${dailyPrayers}) center center/cover`,
      }}></Box>
      <Box sx={{
        height: '300px',
        width: '500px',
        background: `url(${continuesTeaching}) center center/cover`,
      }}></Box>
      <Box sx={{
        height: '300px',
        width: '500px',
        background: `url(${helpingCommunity}) center center/cover`,
      }}></Box> */}
      


    </Box>
    <Grid container       
    disablegutters = "true"
    maxWidth="xl" sx={{
        marginTop: '40px',
        margin: '0 auto'
      }}>
        <Grid item xs={12} md={4} sx={{
          margin: '0 auto',
          textAlign: 'center',
        }}>
          <Button sx={{
            border: 'solid 3px white',
            width: '100%',
            height: '200px',
            marginBottom: '10px',
            textDecoration: 'none',
            color: 'white',
            background: `url(${continuesTeaching}) center center/cover`,
            "@media (max-width: 375px)": {
              width: '100%'
            },
          }}>
          </Button>
          <Typography sx={{
            color: 'white',
          }}>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{
          margin: '0 auto',
          textAlign: 'center',
        }}>
          <Button sx={{
            border: 'solid 3px white',
            width: '100%',
            height: '200px',
            marginBottom: '10px',
            textDecoration: 'none',
            color: 'white',
            background: `url(${dailyPrayers}) center center/cover`,
            "@media (max-width: 375px)": {
              width: '100%'
            },
          }}>
          </Button>
          <Typography sx={{
            color: 'white',
          }}>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{
          margin: '0 auto',
          textAlign: 'center',
        }}>
          <Button sx={{
            border: 'solid 3px white',
            width: '100%',
            height: '200px',
            marginBottom: '10px',
            textDecoration: 'none',
            color: 'white',
            background: `url(${helpingCommunity}) center center/cover`,
            "@media (max-width: 375px)": {
              width: '100%'
            },
          }}>
          </Button>
          <Typography sx={{
            color: 'white',
          }}>
          </Typography>
        </Grid>
      </Grid>
  </Container>;
};

export default TheJMCIMChurch;
