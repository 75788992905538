import React from 'react'
import { Box, Container, Typography, Grid } from "@mui/material";

import jfgc from '../../assets/main/jesus-finest-generation-choir.jpg'
import adultsChoir from '../../assets/main/adults-choir.jpg'



import bgForLocationAndInfo from '../../assets/main/[BG]-TIME-AND-LOC.jpg'
import timeAndLocation from '../../assets/main/[BG]-TIME-AND-LOCATION-NEW.jpg'


function locationAndInfo({ id }) {
  return (
    <>
        <Container
            id={id}
            maxWidth="xxl"
            disableGutters
            color="blue"
            sx={{
                background: `url(${bgForLocationAndInfo}) center center/cover`,
                minHeight:'500px',
            }}
        >   
          <Container maxWidth="lg"
          disablegutters = "true"
          >
              <Grid container>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={timeAndLocation} // Replace with the actual URL of your image
                    alt="Sample"
                    style={{ width: '100%', height: '100%', objectFit: 'cover', marginTop: '150px', marginBottom: '150px' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>
    </>
  )
}

export default locationAndInfo