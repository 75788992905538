import React from "react";
import { Grid, Box, Typography, Link } from "@mui/material";

import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';

import logo from "../../assets/main/logo.png";

const Footer = () => {
  return (
    <Grid container spacing={3}>
      {/* First Column */}
      <Grid item xs={12} sm={12} style={{
        backgroundColor: '#162284'
      }}>
        <Box style={{ padding: "20px", textAlign: "center" }}>
          <img src={logo} alt="" style={{
            padding: '1px',
            width: '80px'
              }} 
            />
          <Box color="white">
            Our Dearest Jesus loves you! Come and have fellowship with us at <strong>6204 30 Street SE, Calgary, AB, Canada. T2C 1Y2</strong>
          </Box>
          <Box sx={{
          alignItems: "center",
            }}>
            {/* Facebook Link */}
            <a href="https://www.facebook.com/jmcim.calgary" target="_blank" rel="noopener noreferrer">
              <FacebookRoundedIcon style={{ cursor: 'pointer', fill: 'white', width: '35px', height: '35px', marginRight: '15px', marginTop: '5px' }} />
            </a>

            {/* YouTube Link */}
            <a href="https://www.youtube.com/@jmcimcgymedia/streams" target="_blank" rel="noopener noreferrer">
              <SubscriptionsRoundedIcon style={{ cursor: 'pointer', fill: 'white', width: '35px', height: '35px', marginLeft: '15px', marginTop: '5px' }} />
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
