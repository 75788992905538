import React from 'react'

function MobileMessage() {
  return (
    <div className="mobile-message">
          Smaller screen sizes may distort or destroy the view as of Jan. 2024 version, please contact Social Media team for further details on this. Thank you so much and God bless you!
    </div>
  )
}

export default MobileMessage