import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { Box, Drawer, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../../assets/main/logo.png";
import { navItems } from "../../data.js";

const Navbar = () => {

  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  return <AppBar position="fixed" elevation={0} sx={{
    backgroundColor: "transparent",
    backdropFilter: "blur(20px)"
  }}>
    <Container
    maxWidth="xl"
    sx={{
      px: 5,
      pt: 3,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 2
    }}
    disablegutters = "true"

    >
      <img src={logo}/>
      <Box sx={{
        color: '#1976d2',
      }}>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex"
          },
          alignItems: "center",
          gap: "54px"
        }}
      >
          <Link
            href="#hero"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Home
          </Link>
          <Link
            href="#thechurch"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            The Church
          </Link>
          <Link
            href="#events"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Recent Events
          </Link>
          <Link
            href="#ministries"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Ministries
          </Link>
          <Link
            href="#contactus"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Contact Us
          </Link>
      </Box>
      {/* Avatar */}
      {/* <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex"
          },
          alignItems: "center",
          gap: "8px"
        }}
      >
        <Box>
          <Typography sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#fff",
            opacity: "0.5"
          }}>
            Praise the mighty name of our Dearest Jesus
          </Typography>
          <Typography sx={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#fff",
            textAlign: "center"
          }}>
            Wilde Ricardo Philippe A. Mallari
          </Typography>
        </Box>
        <img src={userAvatar} alt="avatar" style={{ cursor: "pointer" }} />
      </Box> */}
      {/* End of Avatar */}
      <MenuIcon
        sx={{
          display: {
            xs: "block",
            lg: "none"
          },
          cursor: "pointer",
          color: "black"
        }}
        onClick={() => setOpenMobileMenu(true)}
      />
      <Drawer
        anchor="left"
        open={openMobileMenu}
        onClose={() => setOpenMobileMenu(false)}
      >
        {/* Start of responsive nav */}
        <Box
          sx={{
            position: "relative",
            width: 250,
            backgroundColor: "#white",
            height: "100%",
            py: 3,
            px: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 5
          }}
          role="presentation"
          onClick={() => setOpenMobileMenu(false)}
          onKeyDown={() => setOpenMobileMenu(false)}
        >
          <Box
        sx={{
          display: 'flex',
          alignItems: "center",
          gap: "8px"
        }}
      >
        {/* <Box>
          <Typography sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#fff",
            opacity: "0.5"
          }}>
            Praise the mighty name of our Dearest Jesus
          </Typography>
          <Typography sx={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#fff",
            textAlign: "center"
          }}>
            Wilde Ricardo Philippe A. Mallari
          </Typography>
        </Box> */}
        {/* <img src={userAvatar} alt="avatar" style={{ cursor: "pointer" }} /> */}
          </Box>
          <Box 
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3
            }}
          >
          <Link
            href="#hero"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Home
          </Link>
          <Link
            href="#thechurch"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            The Church
          </Link>
          <Link
            href="#events"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Recent Events
          </Link>
          <Link
            href="#ministries"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Ministries
          </Link>
          <Link
            href="#contactus"
            underline="none"
            color="black"
            sx={{
              fontWeight: "300",
              fontSize: "14px",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                opacity: 1,
              },
              "&:first-of-type": {
                opacity: 1,
              },
            }}
          >
            Contact Us
          </Link>
          </Box>
        </Box>
        {/* End */}
        <Typography
          sx={{
            color: 'black',
            fontWeight: '500',
            cursor: "pointer",
            position: "absolute",
            bottom: "14px",
            left: "50%",
            transform: "translate(-50%)",
            fontSize: 14
          }}
        >
          &#169; JMCIM - Calgary
        </Typography>
      </Drawer>
    </Container>
  </AppBar>;
};
export default Navbar;
